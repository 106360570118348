@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;


.news-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: $max-content-width;

    @include desktop-query {
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;

        gap: $side-padding-mobile;
    }
    @include bigdesktop-query {
        gap: $content-gap-tablet;
    }


    &__item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin-bottom: calc($content-gap-mobile*1.2); // use margin not padding since whole item is linked, so reduce accidental clicks
        gap: calc($content-gap-mobile/5);
        max-width: 430px; // adjust this to same var used to set width on content in offers and other sections
        transition: 1s ease;
        
        &:hover {
            transform: scale(1.05);    
        }

        @include tablet-query {
            margin-bottom: $content-gap-desktop;
            gap: calc($content-gap-mobile/3);
        }

        @include desktop-query {
            margin-bottom: 0;
        }

        // THIS IS CONTROLS THE SIZE OF ITEMS IN THE NEWS LIST FLEX BOX
        // (since link surrounds the item, the flex item directives must be put on the link)
        &-link {
            color: inherit;
            text-decoration: none;

            // These were needed once decided to do multi-row flex
            flex-basis: 31%;
            flex-grow: 1;

            @include desktop-query {
                max-width: 31%; 
                // matches flex-basis. Ensures items in rows that are not full will match width of items in full rows at all screen sizes
                // (without this, items in non-full rows would be larger than items in full rows at some screen sizes as they would try to expand to fill space)
            }
            
        }
        &-thumb {
            border: none;
            border-radius: $border-radius-mobile;
            width: 100%;
            aspect-ratio: 16/9.5; // Enables newsletter jump anchor link to go to right place as otherwise lazy loaded thumbs cause issues with jump position
                // using 16/9.5 instead of 16/9 as latter caused image distortion despite it being exact right dimension for img files
            padding-bottom: calc($content-gap-mobile/2);
        }
        &-category {
            color: $col-brand-primary;
            font-size: 1rem; // calc($font-body-title-mobile*0.8);
            font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
            font-weight: 300;
            line-height: 1.2;

            @include tablet-query {
                font-size: 1.2rem;
            }
        }
        &-title {
            text-align: center;
            color: $col-primary;
            font-size: $font-body-title-mobile;
            // letter-spacing: 0.03rem;
            

            // @include hugemobile-query {
            //     font-size: $font-form-title-mobile;
            // }
            @include tablet-query {
                font-size: $font-form-title-tablet;
            }
        }
        &-date {
            color: $col-text-primary;
        }

        &-placeholder {
            color: $col-text-primary;

        }
        &--unavailable {
            opacity: 0.6;

            &:hover {
                transform: none;
            }
        }
    }
}