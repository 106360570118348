@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

@keyframes pawSteps {
    from {opacity: 0%;}
    to {opacity: 100%;}
    // 0% {opacity: 0%;}
    // 30% {opacity: 100%};
    // 60% {opacity: 100%};
    // 100% {opacity: 0%};
}

.video {
    padding: 0 $side-padding-mobile;
    overflow: hidden;
    display: flex;
    // flex-flow: column nowrap; // added as default was making buttons/links unclickable
    // align-items: center; // new.  shtg about centering this content over the animation area is causing unclickable links
    justify-content: center;
    width: 100%;
    position: relative;
    // top: -1rem;
    top: 3rem; // Added to account for preheader message. Delete when don't need.
    padding: $space-section-padding-mobile $side-padding-mobile;
    margin-bottom: calc($space-section-padding-mobile/2); // required to make padding work for some reason. 

    @include tablet-query {
        // top padding enables the paws to extend 'beyond' the vid without getting cropped by overflow hidden
        // (overflow hidden is to prevent content from scrolling)
        // NB: Paws don't appear until tablet screen width
        padding:  $space-section-padding-tablet $side-padding-mobile;
        margin-bottom: 0; // at tablet & larger, padding works as expected, so set margin to 0
    }

    @include desktop-query {
        top: 0; // Added to account for preheader message. Delete when don't need.
    }


    /* Paw prints around the video
    Why separate divs instead of putting them as bkgd images in 1 div?
     1) Cannot rotate / transform bg images
     2) Can move divs outside borders of containing div; otherwise need to add padding to
     containing div which creates too much white space between it and prev sections
     */ 
    &__paw-group {
        &--left, &--right {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
        }
        &--left {
            @include maxdesktop-query {
                // stop the paws from moving further
                width: $max-content-width*1.1;
                left: 50%;
                transform: translateX(-37.6rem);
            }
            // @include maxdesktop-query {
            //     left: 50%;
            //     transform: translateX(-35rem);
            // }
        }
        &--right {
            @include maxdesktop-query {
                // stop the paws from moving further
                width: $max-content-width*1.1;
                right: 50%;
                transform: translateX(37.3rem);
            }
        }
    }
    &__paw {
        position: absolute;
        // opacity: 0%;
        &1 {
            @include tablet-query {
                background: left top / 100% no-repeat url('../../assets/images/video/paw-lightblue.png');
                width: 5rem;
                height: 5rem;
                top: 0; //-1rem;
                left: 10%;
                transform: rotate(20deg);
                // animation: 2s ease-in-out 2s both pawSteps;
            }
        }
        &2 {
            @include tablet-query {
                background: left top / 100% no-repeat url('../../assets/images/video/paw-orange.png');
                width: 6rem;
                height: 6rem;
                top: 50%;
                left: 0; // -0.25rem;
                transform: rotate(-30deg);
                // transform-origin: top left;
                // animation: 2s ease-in-out 1s both pawSteps;
            }
        }
        &3 {
            @include tablet-query {
                background: left top / 100% no-repeat url('../../assets/images/video/paw-blue.png');
                width: 3rem;
                height: 3rem;
                // top: 90%;
                bottom: 3%;
                left: 15%;
                transform: rotate(40deg);
                // animation: 2s ease-in-out 0s both pawSteps;
            }
        }

        &4 {
            @include tablet-query {
                background: left top / 100% no-repeat url('../../assets/images/video/paw-orange.png');
                width: 2.5rem;
                height: 2.5rem;
                top: 0; //-2rem;
                right: 8%;
                transform: rotate(-25deg);
                // animation: 2s ease-in-out 1.6s both pawSteps;
            }
        }
        &5 {
            @include tablet-query {
                background: left top / 100% no-repeat url('../../assets/images/video/paw-blue.png');
                width: 4.5rem;
                height: 4.5rem;
                top: 30%;
                right: 12%;
                transform: rotate(10deg);
                // animation: 2s ease-in-out 0.8s both pawSteps;
            }
        }
        &6 {
            @include tablet-query {
                background: right bottom / 100% no-repeat url('../../assets/images/video/paw-lightblue.png');
                width: 7rem;
                height: 7rem;
                bottom: 0; //-1rem;
                right: -3rem;
                transform: rotate(-30deg);
                transform-origin: bottom left;
                // animation: 2s ease-in-out 0.3s both pawSteps;
            }
        }       
    }

    &__paw {
        animation-duration: 1s;        
    }

    // &__show-button {
    //     z-index: 10;
    //     border: none;
    //     background: none;
    //     width: 100%;
    //     max-width: 480px;
    // }

    &__show-button-image {
        cursor: pointer;
    }
    &__player, &__show-button-image {
        width: 100%;
        max-width: 480px;
        height: 51vw;
        max-height: 270px;
        aspect-ratio: 16/9;
        border-radius: 0.7rem;  // for YT video
        
        // hide edge lines on some screens
        background-color: white; 
        
        // Ensure above the positioned paw elements
        position: relative;

        @include tablet-query {
            min-width: 30rem; // 35.125rem;
            // explicitly setting the dimensions as some older versions (14.1.2) of Safari do not recognise height: auto;
            width: 480px; // 562px;
            height: 270px; // 316px;
        }
        @include desktop-query {
            // explicitly setting the dimensions as some older versions of Safari do not recognise height: auto;
            max-width: 580px;
            max-height: 330px;
            width: 570px;
            height: 321px;
        }
    }

    // Message that appears when videoplayer cannot be shown due to lack of cookie consent
    &__notification {
        padding: 1rem;

        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        // Most of the styles below match the video__player so that msg has the same dimensions/shape & does not break layout of surrounding items
        width: 100%;
        max-width: 480px;
        height: 51vw;
        max-height: 270px;
        aspect-ratio: 16/9;
        border-radius: 0.7rem; 
        border: 1px solid rgb(201, 201, 201);
        
        // Ensure above the positioned paw elements
        position: relative;

        @include bigmobile-query {
            padding: 1rem 2rem; // purely to make message look better within the box
        }
        @include tablet-query {
            min-width: 30rem; // 35.125rem;
            // explicitly setting the dimensions as some older versions (14.1.2) of Safari do not recognise height: auto;
            width: 480px; // 562px;
            height: 270px; // 316px;
        }
        @include desktop-query {
            // explicitly setting the dimensions as some older versions of Safari do not recognise height: auto;
            max-width: 580px;
            max-height: 330px;
            width: 570px;
            height: 321px;
        }

    }
}