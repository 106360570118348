@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

// Spotlight area ("Featured Activity")
@keyframes spotlightAnim {
    0% {
        transform: translateX(-50%) scale(1) rotate(0deg);
    }
    50% {
        transform: translateX(-50%) scale(1) rotate(180deg);
    }
    100% {
        transform: translateX(-50%) scale(1) rotate(360deg);
    }
}
@keyframes spotlightAnimMaxWidth {
    // used when hit max content width viewport and want to lock content in position
    0% {
        transform: translateX(-57rem) scale(1) rotate(0deg);
    }
    50% {
        transform: translateX(-57rem) scale(1) rotate(180deg);
    }
    100% {
        transform: translateX(-57rem) scale(1) rotate(360deg);
    }
}
.spotlight {
    background-color: $col-spotlight-bg;
    position: relative; // to allow abs posn of imagery
    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    
    // Creates section's bottom padding. For top padding, we do it in spotlight__content for reasons explained below
    padding: 0 0 $space-section-padding-mobile;
    
    @include tablet-query {
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0 0 calc($space-section-padding-tablet*0.7); // Now vert-padding is all handled by __content due to position

        min-height: 26rem;
    }
    @include bigdesktop-query {
        min-height: 28rem;
    }

    &__content {

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.7rem;
        position: relative; // ensures copy sits verticall above the positioned chase & burst imagery
        z-index: 5; // helps ensure is above the background on mobile/tablet
               
        // The top padding here creates the top padding for the whole section.  
        // Why do it here? Because this __content area has a BG linear-grad that blocks out the rotating bg image
        // If instead did padding on parent, there would be no bg lineargrad, so u'd see bg anim again above this __content area
        // Then in tablet breakpoint, __content handles BOTH top & btm padding for same reasons
        padding: $space-section-padding-mobile $side-padding-mobile 0;

        background-image: linear-gradient(to bottom, rgba(20,118,239, 1) 50%, rgba(20,118,239,0) 90%);
        
        @include tablet-query {
            justify-content: center;
            order: 3; 
            padding: $space-section-padding-tablet calc($space-section-padding-tablet*0.7);
            max-width: 29rem;
            min-height: 26rem; // needs to match parent to ensure bg lin-grad fills full height
            background-image: linear-gradient(to left, rgba(20,118,239, 1) 60%, rgba(20,118,239,0) 90%);
        }

        @include bigtablet-query {
            max-width: 33rem;
        }
        @include desktop-query {
            max-width: 40rem;
            padding: $space-section-padding-tablet;
        }
        @include bigdesktop-query {
            max-width: 43rem;
            min-height: 28rem;
            padding-right: calc($space-section-padding-tablet*1.2);
        }
        @include maxdesktop-query {
            // hit max content width, so lock position from this point forward
            position: absolute;
            left: 50%;
            transform: translateX(-6rem);
            // padding-left: 0; 
        }
    }
    &__title, &__subtitle, &__copy {
        text-align: center;
        color: white;
    }
    &__title {
        text-transform: uppercase;
        font-size: $font-page-title-mobile;

        @include desktop-query {
            font-size: $font-page-title-tablet;
        }
    }
    &__subtitle {
        @include bigdesktop-query {
            font-size: 1.3rem;
        }
    }
    &__copy {
        line-height: 1.4;
        position: relative;
        margin-bottom: $side-padding-mobile;

        @include bigdesktop-query {
            font-size: $font-body-tablet;
        }
    }
    &__image-box {
        order: 2;
        height: 20rem;
        width: 100%;
        position: relative;

        @include tablet-query {
            position: absolute;
            height: 100%;
        }
    }
    &__image-bg { // star burst effect behind chase
        position: absolute;
        width: 75rem; //75rem; //35rem;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30rem;
        animation: 20s linear 0s infinite spotlightAnim;

        @include tablet-query {
            // don't forget the anim has a transform on it, so cannot transform this here in css
            left: 25%;// 11rem;
            bottom: -24.5rem;
        }
        @include bigtablet-query{
            left: 14rem;
        }
        @include bigdesktop-query{
            left: 18rem;
            bottom: -24.5rem;
        }
        @include maxdesktop-query{
            left: 50%;
            animation: 20s linear 0s infinite spotlightAnimMaxWidth; // switch to diff animation that has a static translateX value
        }
        @include prefers-reduced-motion {
            animation:none;
            transform: translateX(-57rem);
        }
    }
    &__image-fg { // Chase the dog character
        position: absolute;
        width: 90%;
        max-width: 15rem;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        @include tablet-query {
            max-width: none;
            width: 17rem;
            left: 27%; // 12rem;
            bottom: 3rem;
        }
        @include bigtablet-query{
            left: 15rem;
        }
        @include bigdesktop-query{
            left: 19rem;
            width: 18rem;
        }
        @include maxdesktop-query{
            left: 22rem;
        }
        @include maxdesktop-query {
            // hit max content width, so lock position from this point forward
            left: 50%;
            transform: translateX(-27.5rem); 
        }
    }
}