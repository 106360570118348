@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

// NEWSLETTER LOWER AREA RELATED
.bottom-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    padding: $space-section-padding-mobile 0;

    @include tablet-query {
        // use less upper padding as there is excess white space in section above plus the form has its own margin
        padding:  $space-section-padding-mobile 0 $space-section-padding-tablet;
    }
    @include desktop-query {
        flex-flow: row nowrap;
        justify-content: center;
        
    }

    &__image {
        display: none;

        @include desktop-query {
            display:initial;
            max-width: 500px;
            object-fit: contain;
        }
        
    }

}

.header__form {
    max-width: $space-header-content-mobile;

    border: 1px solid $col-form-border;
    border-radius: 10px;
    
    // made upper margin larger to accomodate new preorder bar above it
    margin: $space-tablet-between 0 calc($space-mobile-between/2);
    padding: calc($space-mobile-between * 2) calc($space-mobile-between*0) calc($space-mobile-between * 2.6);

    position: relative;

    @include medmobile-query {
        padding: calc($space-mobile-between * 2) calc($space-mobile-between) calc($space-mobile-between * 2.6);
    }

    @include bigmobile-query {
        padding: calc($space-mobile-between * 2) calc(2*$space-mobile-between) calc($space-mobile-between * 2.6);
    }
    
    @include tablet-query {
        max-width: $space-header-content-tablet;
        margin-top: calc($space-header-padding-tablet/1.2); // added this for space btwn preorder & form
    }

    @include desktop-query {
        padding-top: calc($space-mobile-between*2.8);
        padding-bottom: calc($space-mobile-between*3);
    }

    &:not(.bottom-form__form) {
        @include desktop-query {
            // Shifts form contents around to account for logo at top now
            margin-top: 8rem;
            padding-top: 8rem;
        }
    }
    

    background-color: white;

    &-logo {
        display: none;

        &:not(.bottom-form__logo) {
            @include desktop-query {
                display: block;
                width: 8.5rem; //8rem;
                margin: 0 auto;

                position: absolute;
                top: -5rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        
    }

    &-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: $space-mobile-between;
    }
    &-title {
        font-size: $font-form-title-mobile;
        color: $col-primary;
        text-align: center;
        @include tablet-query {
            font-size: $font-form-title-tablet;
        }
    }
    &-subtitle {
        font-size: $font-form-copy-mobile;
        color: $col-brand-primary;

        @include tablet-query {
            font-size: $font-form-copy-tablet;
        }
    }
}

// Inline form (not iframed)
.form-inline {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.7rem;
    max-width: 16.2rem;
    margin: 0.2rem 1.3rem 0rem; //0.7rem;

    &--hide {
        display: none;
    }

    &__warning, &__checkbox-label, &__input::placeholder {
        // font-family:Arial, Helvetica, sans-serif;

        font-size: 0.85rem;
        letter-spacing: normal;
    }
    &__input,  &__input::placeholder {
        // Form input size must be >= 16px in order to stop Safari mobile from auto-zooming into the form when click into a field
        font-size: 1rem;
        font-family: "Gotham Light", Arial, Helvetica, sans-serif;
    }

    &__warning {
        color: $col-error;
        font-style:italic;
        font-weight: 700;
        line-height: 1.4rem;
        padding: 0 0.1rem;

        &-hide {
            display: none;
        }
    }
    &__radiobox-container {
        padding: 0 0.8rem;
    }
    &__radiobox-label {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        color: black;
        line-height: 1.4rem;
        vertical-align: text-top;
        padding-left: 1.2rem;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.3rem;
    }
    &__checkbox-container {
        padding: 0 0.8rem;
        display: flex;
        flex-flow: row nowrap;
        text-align: left;

        &--terms {
            display: none !important;
            & label {
                display: none !important;
            }
            & input {
                display: none !important;
            }
            & span {
                display: none !important;
            }
        }
    }
    &__checkbox {
        position: relative;
        top: 0.2rem;
        // width: 1rem !important; //3rem;
        max-width: 1rem;
    }
    &__checkbox-label {
        color: black;
        line-height: 1.4rem;
        vertical-align: text-top;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.3rem;
    }
    &__recaptcha-container {
        position: relative;
        left: -1.29rem;
    }
    &__input {
        // width: 100%;
        padding: 0.55rem 0.3rem;
    }
    &__button {
        position: relative;
        background-color: $col-primary;
        width:12.7rem;
        padding: 0.7rem 1rem; // 0.7rem 3.3rem;
        border: none;
        border-radius: 0.6rem;

        font-family:"Bango Pro", "PPBango", sans-serif;
        font-size: 1.625rem;
        color: white;

        align-self: center;

        &:hover {
            cursor: pointer;
        }

        &-loading {
            width: 2rem;
            vertical-align: text-bottom;
            // position: absolute;
            // left:1.1rem;
            // bottom: 0.7rem;
        }
    }
    &__dob-fields-box {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        width: 100%;

        & input {
            // flex-basis: 100%;
            min-width: 0;
            
        }
    }
    &__hide-misc {
        // make sure this is below other display declarations so that it has precedence
        display: none;
    }
}

// Form success message
.form__success {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-width: 18rem;
    &--hide { 
    // ENSURE THIS IS BELOW other display declarations so it has precedence
        display: none;
    }
    &-copy {
        text-align: center;
        line-height: 1.4rem;

        &-android-note {
            // used if android is not available
            font-family:Arial, Helvetica, sans-serif;
        }
    }
    &-icons-box {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.7rem;

        margin: 1rem 0;
    }
    &-icon {
        width: 9rem;
    }
    &-share-button {
        padding: 0 0.5rem;
    }
    &-buttons-box {
        text-align: center;
        // margin: 1rem;
        // padding-bottom: 1rem;
        // display: flex;
        // flex-flow: row nowrap;
        // justify-content: space-between;
        // max-width: 240px;
        // @include desktop-query {
        //     max-width: 210px;
        // }
    }
}

.form {
    &__show-block {
        height: 0;
        margin: 0;
        padding: 0;
        position: absolute;
    }
    &__show-on-mobile {
        display: block;
        @include desktop-query {
            display: none;
        }
    }
    &__show-on-desktop {
        display: none;
        @include desktop-query {
            display: block;
        }
    }
}
// Form Network Error Message
.form__error {
    &-copy {
        color: $col-error;
        text-align: center;
        font-family: "Bango Pro", "PPBango",Arial, Helvetica, sans-serif;
        color: $col-error;
        line-height: 1.4rem;
        padding: 0 0.1rem;
    }
    &--hide {
        display: none;
    }
}