@use "./partials/colors" as *;
@use "./partials/variables" as *;
@use "./partials/fonts";
@use "./partials/mixins" as *;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;// If i put this in body selector, then h1 had top margin for some reason
}
body {
    font-family: "Metropolis Light", Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.3;
}
h1, h2, h3, h4 {
    font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.2;

    color: $col-primary;
}
h1 {
    font-size: $font-page-title-mobile;
    
    @include tablet-query {
        font-size: $font-page-title-tablet;
    }
    @include desktop-query {
        font-size: $font-page-title-desktop;
    }
}
h2 {
    font-size: $font-section-title-mobile;
    @include tablet-query {
        font-size: $font-section-title-tablet;
    }
    @include desktop-query {
        font-size: $font-section-title-desktop;
    }
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.spacer-generic {
    min-height: 2rem;
}

.site-container {
    // max-width: $max-content-width;
    margin: 0 auto;
    position: relative;
}

// For onetrust cookie consent
#onetrust-consent-sdk * {
    font-family: "Metropolis Light", Arial, Helvetica, sans-serif !important;
}
#onetrust-policy-text {
    color: $col-text-primary !important;
}
button#onetrust-pc-btn-handler {
    background-color: #B2B4B2 !important;
}

.download-button {
    color: $col-primary;
    background-color: $col-secondary;
    font-family: "Bango Pro", Arial, Helvetica, sans-serif;
    font-size: $font-section-title-mobile;
    text-transform: uppercase;

    padding: 1rem 1.9rem;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;    

    &:hover {
        transform: scale(1.1);
        transition: transform 0.8s ease-in-out;
    }
}

.error404-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
    padding: 1rem;
}