@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;


.footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &__social {
        background-color: $col-secondary;
        padding: $space-mobile-between*1.5;
        
        width: 100%;
        text-align: center;
        // display: flex;
        // justify-content: center;
        // align-items: self-end;
        // gap: 1rem;
        &-title {
            color: $col-primary;

            @include tablet-query {
                font-size: 130%;
            }
        }
        &-box {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            gap: $space-mobile-between*2;

            margin-top: $space-mobile-between;
            @include tablet-query {
                gap: $space-tablet-between*1.5;
            }
        }
        &-link {
            text-decoration: none;
        }
        &-icon {
            // color: rgb(207, 207, 207);
            filter: brightness(0) saturate(100%) invert(18%) sepia(95%) saturate(2768%) hue-rotate(208deg) brightness(91%) contrast(91%);
            height: 30px;
            object-fit: contain;

            @include tablet-query {
                height: 40px;
            }

        }
    }
    &__logos-legal-box {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    
        width: 100%;
        padding: $side-padding-mobile 1.5rem 0.7rem;

        background-color: $col-primary;
        color: rgb(213, 213, 213); // white;
    }
    &__links {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        margin: 1rem 0 0;
        font-size: 0.8rem;

        padding: calc($side-padding-mobile/2) 0;

        @include bigmobile-query {
            flex-flow: row wrap;
        }
            
        &-item {
            font-size: 1rem;
            text-decoration: none;
            color: rgb(213, 213, 213);// white; //$col-text-primary;  
            &:hover {
                text-decoration: underline;
            }

            &-button {
                border: none;
                background-color:transparent;
                cursor:pointer;
            }
        }

        &-divider {
            display: none;
            @include bigmobile-query {
                display: initial;
            }
        }
    }
    &__legal {
        font-size: 0.8rem;
        margin: 1rem;
        
        text-align: center;

    }
    &__kidsafe {
        margin: 0.1rem 0 0.5rem;
    }
    &__logos {
        margin-top: 1rem;
        padding: 0;

        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        &-image {
            height: 50px;

            @include tablet-query {
                height: 55px;
            }

        }
    }
}
