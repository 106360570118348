@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.error-message {

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 $side-padding-mobile $content-gap-desktop;
    min-height: 45vh;

    @include desktop-query {
        padding: 0 $side-padding-desktop $content-gap-desktop;
    }

    &__title {
        text-align: center;
    }
}