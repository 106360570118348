@use '../../styles/partials/colors' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.printable-details {

    &__print-btn {
        background-color: $col-primary;
        color: white;

        &:active {
            color: grey;
        }
    }
    &__divider {
        border-top: 2px solid $col-brand-primary;
        margin: $content-gap-desktop 0;

    }
    &__related {
        min-width: 100%; // Without this, iPhone has issue whereupon first load of the "more printables" list on a details page, the items don't fill width of container. 
        @include desktop-query {
            margin-bottom: $content-gap-desktop;
        }
    }
    &__related-title {
        text-align: center;
        margin-bottom: $content-gap-mobile;
        @include tablet-query {
            margin-bottom: $content-gap-tablet;
        }
        @include desktop-query {
            text-align: left;
        }
        
    }
}