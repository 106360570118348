@use "./partials/colors" as *;
@use "./partials/variables" as *;
@use "./partials/fonts";
@use "./partials/mixins" as *;

.accordion {
    
    padding: $space-mobile-between;
    padding-bottom: $section-gap-mobile;

    @include tablet-query {
        padding: $section-gap-mobile $section-gap-mobile $section-gap-tablet;
    }

    &__container {
        max-width: $max-content-width;
        margin: 0 auto;
        overflow:hidden;
        padding-top: $section-gap-mobile + $preheader-height; // added the extra height to account for preheader banner

        @include desktop-query { // added this to remove the preheader banner height on desktop as unnecessary
            padding-top: $section-gap-mobile;
        }
        
    }
    &__answer {
        font-size: 0.95rem;
        padding-bottom: $space-mobile-between;

        @include bigmobile-query {
            font-size: 1rem;
        }
    }
    &__subtitle {

        &-box {
            width: fit-content;
            height: fit-content;
            position: relative;
        }
        &-bg {
            position: absolute;
            left: -4rem;

            transform: skew(340deg);
            height: 100%;
            width: 100%;
            background-color: $col-secondary;
            
            @include tablet-query {
                transform: skew(330deg);
                left: -3rem;
            }
            
        }
        &-fg {
            position: absolute;
            top: -0.5rem;
            left: -4.5rem;

            transform: skew(340deg);
            height: 100%;
            width: 100%;
            background-color: $col-primary;

            @include tablet-query {
                transform: skew(330deg);
                left: -3.5rem;
            }
            
        }
        &-copy {
            position: relative;
            left: -4rem;
            color: white;
            font-size: calc($font-section-title-mobile*0.85);
            padding: 0.3rem 2.3rem 1.3rem 5rem;

            @include tablet-query {
                left: -3rem;
                font-size: $font-section-title-mobile;
                padding: 0.6rem 3.5rem 1.6rem 5rem;
            }
        }
    }
}
.accordion > div > div:first-of-type {
    border-top: 1px solid #999;
    padding: $space-mobile-between 0;
    padding-right: calc($space-mobile-between*1.25);
    font-family: "Bango Pro", "Arial Black", Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    color: $col-primary;
    background-image: url("../assets/images/misc/caret-open.gif");
    background-size: 1.1rem;
    background-repeat: no-repeat;
    background-position: right 0rem top calc($space-mobile-between*1.3);

    cursor: pointer;

    @include bigmobile-query {
        font-size: 1.2rem;
    }
    @include tablet-query {
        padding-right: 1.8rem;
        background-size: 1.5rem;
    }
    @include desktop-query {
        font-size: 1.3rem;
        padding-right: 2.4rem;
        background-size: 2rem;
    }
  }
  .accordion > .accordion__item--open > div:first-of-type {
    background-image: url("../assets/images/misc/caret-close.gif");
  }