@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.news-home {

    width: 100%;
    padding: $space-section-padding-mobile $side-padding-mobile;
    margin-top: $space-section-padding-mobile; // Creates btm-padding in Offer section above. Did this as Offer has complicated absol sizing of divs that break when add padding
    background-color: $col-activities-background;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include desktop-query {
        padding: $space-section-padding-tablet $side-padding-tablet;
    }

    &__title {
        padding-bottom: $space-section-padding-mobile; // $content-gap-mobile;
        font-size: $font-section-title-mobile;
        
        @include tablet-query {
            font-size: $font-section-title-tablet;
        }

        &-link {
            color: inherit;
            text-decoration: none;
            // transition: 1s ease;
            // &:hover {
            //     transform: scale(1.05);
            // }
        }
    }
    
    &__list {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        max-width: $max-content-width;

        @include desktop-query {
            flex-flow: row nowrap;
            align-items: flex-start;
    
            gap: $side-padding-mobile;
        }
        @include bigdesktop-query {
            gap: $content-gap-tablet;
        }
    }

    &__item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin-bottom: calc($content-gap-mobile*1.2); // use margin not padding since whole item is linked, so reduce accidental clicks
        gap: calc($content-gap-mobile/5);
        max-width: 430px; // adjust this to same var used to set width on content in offers and other sections
        transition: 1s ease;

        &:hover {
            transform: scale(1.05);    
        }

        @include tablet-query {
            margin-bottom: $content-gap-desktop;
            gap: calc($content-gap-mobile/3);
        }

        @include desktop-query {
            max-width: none;
            margin-bottom: 0;
        }
        &-thumb {
            border: none;
            border-radius: $border-radius-mobile;
            width: 100%;
            padding-bottom: calc($content-gap-mobile/2);
        }
        &-category {
            color: $col-brand-primary;
            font-size: 1rem; // calc($font-body-title-mobile*0.8);
            font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
            font-weight: 300;
            line-height: 1.2;

            @include tablet-query {
                font-size: 1.2rem;
            }
        }
        &-title {
            text-align: center;
            color: $col-primary;
            font-size: $font-body-title-mobile;
            // letter-spacing: 0.03rem;
            
    
            // @include hugemobile-query {
            //     font-size: $font-form-title-mobile;
            // }
            @include tablet-query {
                font-size: $font-form-title-tablet;
            }
        }
        &-date {
            color: $col-text-primary;
        }
        &-link {
            color: inherit;
            text-decoration: none;
        }
        &-placeholder {
            color: $col-text-primary;

        }
        &--unavailable {
            opacity: 0.6;

            &:hover {
                transform: none;
            }
        }
    }
}