@use 'colors' as *;
@use 'variables' as *;


@mixin prefers-reduced-motion {
    @media (prefers-reduced-motion){
        @content;
    }
}

@mixin medmobile-query {
    @media screen and (min-width: 301px){
        @content;
    }
}

@mixin med2mobile-query {
    // primarily used for fine control of where text breaks & visual padding in the Features section's slanted boxes
    @media screen and (min-width: 315px){
        @content;
    }
}
@mixin med3mobile-query {
    // primarily used for homepage title size to ensure it does not wrap to more than 2 rows
    @media screen and (min-width: 333px){
        @content;
    }
}

// from landing page welcome page incorporatino
@mixin verysmall-query {
    @media screen and (max-width: 340px){
        @content;
    }
}
@mixin section-item-desc {
    font-family: "Gotham Bold", Arial;
    letter-spacing: normal;
    color: inherit;
}


@mixin commonmobile-query {
    @media screen and (min-width: 390px) {
        @content;
    }
}
@mixin bigmobile-query {
    // @media screen and (min-width: 426px) {
    @media screen and (min-width: 435px) {
        @content;
    }
}

@mixin hugemobile-query {
    @media screen and (min-width: 564px) {
        @content;
    }
}

// Used to briefly move the awards badge up and down on homepage due to text wrapping pushing it up
@mixin hugemobile2-query {
    @media screen and (min-width: 586px) {
        @content;
    }
}


@mixin pretablet-query {
    @media screen and (min-width: 701px) {
        @content;
    }
}

@mixin tablet-query {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin bigtablet-query {
    @media screen and (min-width: 910px) {
        @content;
    }
}
@mixin desktop-query {
    @media screen and (min-width: 1023px){
        @content;
    }
}
@mixin bigdesktop-query {
    @media screen and (min-width: 1110px){
        @content;
    }
}

@mixin maxdesktop-query {
    @media screen and (min-width: 1245px){
        @content;
    }
}

@mixin bigmaxdesktop-query {
    // used mainly for header navbar when in compact header mode
    @media screen and (min-width: 1340px){
        @content;
    }
}

// @mixin section-item-desc {
//     font-family: "Gotham Bold", Arial;
//     letter-spacing: normal;
//     color: inherit;
// }
