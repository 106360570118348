$col-primary: #0B53B5; //#004b87;
$col-secondary: #FED132; // #F5A927;
$col-activities-bg: #E9F3F7; // #00a3e0; 
$col-spotlight-bg: #1476EF;
$col-error: rgb(204, 51, 0);

// PREVIOUS:
$col-brand-primary: #00a3e0;
$col-form-border: rgb(216,215,215);
$col-activities-background: #e8f3f7; // orig:  rgb(242, 249, 249);
$col-text-primary:  rgb(114,114,114); // #666666
$col-brand-red: #F50F29; 
$col-brand-orange: #F5A927; // #eaaa00;
$col-brand-blue-dark: #004b87;
$col-grey-verydark: #54585A; // PMS 425C
$col-grey-dark: #898D8D; // PMS 423C
$col-grey-mid: #B2B4B2; // PMS 421C
$col-grey-light:  #C7C9C7; // PMS 420C
//$col-error: rgb(204, 51, 0);