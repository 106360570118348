$max-content-width: 80rem;

$preheader-height: 3rem;

$side-padding-mobile: 1rem;
$side-padding-tablet: 1.5rem;
$side-padding-desktop: 2.5rem; //2.5rem;
$side-padding-bigdesktop: 3rem; //2.5rem;

$vert-padding-mobile: 1rem;
$vert-padding-tablet: 1rem;
$vert-padding-desktop: 1.5rem;

$content-gap-mobile: 1rem;
$content-gap-tablet: 1.5rem;
$content-gap-desktop: 2.5rem;

$section-gap-mobile: 2rem;
$section-gap-tablet: 3rem;
$section-gap-desktop: 4rem;

$border-radius-mobile: 1rem;
$border-radius-tablet: 1.3rem;
$border-radius-desktop: 1.5rem;

$font-page-title-mobile: 1.95rem; //1.85rem;
$font-page-title-tablet: 2.6rem; //2.3rem;
$font-page-title-desktop: 3rem;

$font-section-title-mobile: 1.55rem;
$font-section-title-tablet: 2.1875rem;  // h1
$font-section-title-desktop: 2.8125rem;

$font-tertiary-title-mobile: 0.9rem;
$font-tertiary-title-tablet: 1.1rem;
$font-tertiary-title-desktop: 1.2rem;

$font-body-title-mobile: 1.2rem; //1.15rem;
$font-body-title-tablet: 1.5rem;
$font-body-title-desktop: 1.8rem;
$font-body-mobile: 1rem;
$font-body-tablet: 1.2rem;

/* 
* PREVIOUS STUFF FROM LANDING PAGE
* common spacing per styleguide
* assumed 16px default font size
*/

// $font-section-title-mobile: 1.625rem;


$font-activity-mobile: 1.1rem; //.9375rem;
$font-activity-tablet: 1.3rem;    // h3

$font-feature-mobile: 1.25rem;
$font-feature-tablet: 1.3rem;

$font-date-mobile: 1.625rem; //1.5234rem;
$font-date-tablet: 1.9rem; // 2.1875rem; //1.75rem;
$font-date-desktop: 2.1875rem; 

$font-subtitle-mobile: 1.2188rem;
$font-subtitle-tablet: 1.6rem; // 1.38rem;  // h2

$font-form-title-mobile: 1.4063rem;
$font-form-title-tablet: 1.5rem;
$font-form-copy-mobile: 1rem;
$font-form-copy-tablet: 1rem;

$space-max-width-mobile: 20rem;
$space-header-content-mobile: 20rem;  // width of header content columns 
$space-header-content-tablet: 36.5625rem;

$space-header-padding-mobile: 0.9375rem;
$space-header-padding-tablet: 2.5rem;

$space-mobile-between: .9375rem;
$space-tablet-between: 1.4rem;

$space-section-padding-mobile: 2.5rem;
$space-section-padding-tablet: 3.75rem;




// $space-mobile-prehead: 1.5rem;
// $space-mobile-posthead: 1rem;


// $space-tablet-prehead: 2.25rem;
// $space-tablet-posthead: 1.5rem;

// $space-desktop-sides: 8.4rem;

// $padding-tablet-button: 2rem;

// $max-content-width: 112.5rem; // 1800px

// $max-video-width-desktop: $max-content-width - calc($space-desktop-sides *1.5);

