@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

// @keyframes offerAppear {
//     0% {
//         transform: translate(-50%, -50%) scale(0.5);
//     }
//     100% {
//         transform: translate(-50%, -50%) scale(1);
//     }
// }
// @keyframes offerExpand {
//     0% {
//         transform: translate(-50%, -50%) scale(0.5);
//         opacity: 0.1;
//     }
//     10% {
//         transform: translate(-50%, -50%) scale(0.6);
//         opacity: 1;
//     }
//     25% {
//         transform: translate(-50%, -50%) scale(0.75);
//         opacity: 1;
//     }
//     75% {
//         transform: translate(-50%, -50%) scale(1.25);
//         opacity: 1;
//     }
//     90% {
//         transform: translate(-50%, -50%) scale(1.4);
//         opacity: 1;
//     }
//     100% {
//         transform: translate(-50%, -50%) scale(1.5);
//         opacity: 0.1;
//     }
// }
@keyframes offerPulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.97);
    }
    100% {
        transform: scale(1);
    }
}
.offer {
    width: 100%;
    min-height: 35rem;
    overflow: hidden;
    // padding: calc($space-section-padding-mobile*0.5) 0 0; // halving top padding as graphic has own white space already
    padding: $space-section-padding-mobile 0 0;

    position: relative;

    &__content {
        // this combined with placing html later allows content to show above the abs positioned images
        position: relative; 

        height:35rem; // should match .offer's height
        width: 100%;
        padding: 0 $side-padding-mobile;
        overflow: hidden;
        
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: calc($content-gap-mobile*0.6);

        @include tablet-query {
            height: 39rem;
        }
    }
    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: $font-page-title-mobile;
    }
    &__subtitle {
        text-align: center;
    }
    &__button {
        text-transform:initial;
        margin: 1rem 0 2rem;

        animation: 1.6s ease-in-out 0s infinite offerPulse;

        &:hover {
            animation-play-state: paused;
        }
        @include tablet-query {
            margin: 0.6rem 0 1.3rem;
        }
        @include prefers-reduced-motion {
            animation: none;
        }
    }
    &__store-box{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 0.1rem;

        @include medmobile-query {
            gap: 0.5rem;
        }
    }
    &__store-icon {
        width: 7.5rem;

        @include medmobile-query {
            width: 9rem;
        }
        @include tablet-query {
            width: 10rem;
        }
        
    }
    // background starburst img
    &__bg {
        height: 100%;
        position: absolute;
        top: 52.3%; //50%;
        left: 54%;
        
        width: 24rem; 
        background: center center/110% url("../../assets/images/offer/combined_mobile.webp") no-repeat white;
        // transform: translate(-50%, -50%);
        // animation: 1s ease-out 0s offerAppear;
        // animation: 15s linear 0s infinite offerExpand; // continuous flow

        @include tablet-query {
            background: center center url("../../assets/images/offer/combined_desktop.webp") no-repeat white;
            background-size: 100%;
            width: 60rem;
            top: 48%;
            left: 50%;
            
        }
    }
    // &__fg {
    //     position: absolute;

    //     &-01 { // pink color 
    //         width: 5.5rem;
    //         right: 50%;
    //         transform: translateX(-55%);
    //         top: 4rem;
    //     }
    //     &-02 { // crown ABC
    //         width: 7.5rem;
    //         transform: translateX(0%);
    //         left: 50%;
    //         top: 1.5rem;
    //     }
    //     &-03 { // farm
    //         width: 6.3rem;
    //         left: 50%;
    //         transform: translateX(38%);
    //         bottom: 3.1rem;
            
    //     }
    //     &-04 { // dance floor
    //         width: 8rem;
    //         bottom: 1rem;
    //         right: 50%;
    //         transform: translateX(-5%);
    //     }
    // }

}

