@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.features {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: calc($content-gap-mobile*2);
    padding: $vert-padding-mobile $side-padding-mobile;
    // padding: 0 $side-padding-mobile;

    overflow-x: hidden; // to prevent horiz screen scroll from animated items on safari browsers
    
    @include medmobile-query {
        gap: calc($content-gap-mobile*3);
    }
    @include tablet-query {
        gap: calc($section-gap-desktop*1.5);
        padding: $vert-padding-tablet $side-padding-tablet;
        
    }

    &__title {
        text-align: center;
        font-size: $font-section-title-mobile;

        @include tablet-query {
            font-size: $font-section-title-tablet;
            padding: $section-gap-mobile 0;
        }

    }

    // Each of the feature item boxes
    &__item {
        width: 90%; //85%;
        max-width: 30rem;
        height: 5.5rem; //5rem;

        @include medmobile-query {
            width: 95%;
        }
        @include med2mobile-query {
            width: 92%;
        }
        @include commonmobile-query {
            width: 90%;
        }
        @include hugemobile-query {
            height: 6rem;
        }
        @include tablet-query {
            max-width:42rem;
        }
        @include maxdesktop-query {
            // max-width: 43rem;
        }
    }
    &__box-bg {
        background-color: $col-secondary;
        transform: skew(340deg);
        height: 100%;
        width: 100%;
        position: relative;

        @include tablet-query {
            transform: skew(330deg);
        }
    }
    &__box-fg {
        background-color: $col-primary;
        height: 100%;
        width: 100%;
        position: absolute;
        left: -0.5rem;
        top: -0.5rem;       
    }

    &__copy-box {
        transform: skew(20deg);
        padding: 0.7rem 0.9rem 0 1.4rem;

        @include medmobile-query {
            padding: 0.7rem 0.5rem 0 1.4rem;
        }
        @include tablet-query {
            transform: skew(30deg);
            padding: 1rem 1rem 1rem 2.5rem;
        }

        &--aplus {
            @include tablet-query {
                // position: relative;
                position: absolute;
                left: 15.5rem;
            }
        }
        &--free-time {
            @include tablet-query {
                // position: relative;
                position: absolute;
                left: 12rem;
            }
        }
    }

    &__copy-title {
        color: white;
        font-weight: 200;
        font-size: $font-body-title-mobile;
        letter-spacing: 0.03rem;

        @include hugemobile-query {
            font-size: calc($font-body-title-mobile*1.1);
            // font-size: $font-section-title-mobile;
            font-weight:normal;
        }
        @include tablet-query {
            font-size: calc($font-section-title-tablet*0.8);
        }
    }
    &__copy {
        color: white;// #EFF4FA;
        @include hugemobile-query {
            font-size: calc($font-body-mobile*1.1);
        }
        @include tablet-query {
            font-size: $font-body-tablet;
        }
        
    }
    &__image {
        // transform: skew(20deg);
    }
    &__image--aplus {
        width: 7rem;
        position: absolute;
        right: 0px;
        bottom: -2.1rem;

        transform: rotate(-3deg);

        @include hugemobile-query {
            width: 9rem;
            transform: rotate(-10deg);
            bottom: -1.7rem;
        }
        @include tablet-query {
            left: -2.4rem;
            // transform: translateX(-100%);
            bottom: -0.5rem;
            width: 18rem;
        }
    }
    &__image--challenges {
        width: 8.125rem;
        position: absolute;
        right: -0.5rem;
        bottom: -2rem;

        @include hugemobile-query {
            width: 10.2rem;
        }
        @include tablet-query {
            width: 21rem;
            right: -1rem;
            bottom: -1.5rem;
        }
    }
    &__image--free-time {
        width: 6.25rem;
        position: absolute;
        right: -1rem;
        bottom: -2.3rem;

        @include hugemobile-query {
            width: 7.6rem;
            right: 0;
            bottom: -2rem;
        }
        @include tablet-query {
            left: -2rem;
            // transform: translateX(-100%);
            bottom: -2rem;
            width: 15rem;
        }
    }
    &__image--offer {
        width: 7.5rem;
        position: absolute;
        right: -0.6rem;
        bottom: -0.8rem;
        @include hugemobile-query {
            width: 9.8rem;
        }
        @include tablet-query {
            width: 22rem;
        }
    }

    &__image {
        display: none;
        @include medmobile-query {
            display:inline;
        }
    }
}