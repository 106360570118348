@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/variables" as *;
@use "../../../styles/partials/fonts";
@use "../../../styles/partials/mixins" as *;

.legal-copy {
    font-size: 0.6rem;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 0.7rem;
}
.header-landing {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    padding-bottom: $space-section-padding-mobile;

    @include tablet-query {
        padding-bottom: $space-section-padding-tablet;
    }
    @include desktop-query {
        flex-flow: row nowrap;
        align-items:flex-start;
        justify-content: center;
        gap: 40px;

        padding-bottom: 1rem; //$space-section-padding-tablet - 1rem;
    }

    &__info {
        
        max-width: $space-header-content-mobile;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: $space-mobile-between;
        padding: $space-mobile-between 0;
        // justify-content: space-between;

        @include tablet-query {
            max-width: 35.125rem; // 562px;
            // padding: $space-header-padding-tablet*0.5 0 $space-header-padding-tablet; // $space-header-padding-tablet 0;
            padding: $space-header-padding-tablet*0.65 0 calc($space-header-padding-tablet/2); 
                // Because we added preorder bar below this, I divided above lower padding in 2 and added other half to
                // upper margin of the form box (which is below the preorder bar)

            gap: $space-tablet-between;
        }

        @include desktop-query {
            max-width: 600px;
            padding: $space-header-padding-tablet 0 0; // padding-bottom: 0;
        }

        // Coming Soon / Pre-Order Now
        &-date {
            display: inline;
            font-weight: normal;
            font-size: $font-date-mobile;
            color: $col-primary;
            text-transform: uppercase;
            text-align: center;

            @include verysmall-query {
                font-size: calc($font-date-mobile * 0.85);
            }

            @include tablet-query {
                font-size: $font-date-tablet*1.2;
                text-transform: capitalize;
            }
            @include desktop-query {
                font-size: $font-date-desktop*1.2;
                // text-transform:none;
                color: $col-primary;
            }
        }
        // title is replaced by logo on mobile devices
        &-title {
            display: none;

            @include desktop-query {
                display: block;
                color: $col-brand-primary;
                font-size:  $font-form-title-tablet; // $font-section-title-desktop*0.6;                
            }

            &--tm {
                font-size: 50%;
                vertical-align:text-top;
            }
        }
        &-logo {
            width: 7.75rem; //9.3rem; // 10.5rem;// 6.25rem;
            object-fit: contain;

            @include tablet-query {
                width: 10rem; // 10.5rem; //11.5rem; // 8rem;
            }
            @include desktop-query {
                display: none;
            }
        }
        // header copy and video area
        &-content {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: $space-mobile-between*0;
            
        }
        &-video {
            width: 100%;
            min-width: 270px; //$space-header-content-mobile;
            height: auto;
            object-fit: contain;
            object-position: left top;
            //aspect-ratio: attr(width) / attr(height); // proper CLS way though may not work on iframes
            aspect-ratio: 16/9;
            border-radius: 0.7rem;  // for YT video

            margin: calc($space-mobile-between/2) 0;

            background-color: white; // hide edge lines on some screens

            @include tablet-query {
                min-width: 30rem; // 35.125rem;
                // explicitly setting the dimensions as some older versions (14.1.2) of Safari do not recognise height: auto;
                width: 480px; // 562px;
                height: 270px; // 316px;
            }
            @include desktop-query {
                // explicitly setting the dimensions as some older versions of Safari do not recognise height: auto;
                width: 570px;
                height: 321px;
            }
        }
        &-subtitle {
            font-family:"Bango Pro","PPBango";
            color: $col-text-primary;
            font-size: $font-subtitle-mobile;
            line-height: 1.3;

            @include tablet-query {
                font-size: $font-subtitle-tablet;
            }
            @include desktop-query {
                //font-size: 25px; // Adjust to ensure 1st 2 subs on same line for desktop.
            }

            &-group {
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                position: relative;
                @include desktop-query {
                    order: 1;
                    flex-flow: row nowrap;
                    gap: 0.5rem;
                }

                &-sticker { // Win Sticker next to prize banners
                    position: absolute;
                    top: -2.14rem;
                    left: 1.3rem; // 1.3rem;

                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: center;

                    background-color: $col-brand-blue-dark; // $col-primary; //
                    border-radius: 100%;
                    width: 2.7rem;
                    height: 2.7rem;
                    transform: rotate(-20deg);

                    color: $col-secondary;
                    font-family: 'Bango Pro', sans-serif, Arial, Helvetica;
                    font-size: 1rem;

                    @include tablet-query {
                        left: -0.5rem;
                        top: -1.5rem;
                        width: 3.5rem;
                        height: 3.5rem;
                        font-size: 1.3rem;
                    }
                    @include desktop-query {
                        left: -0.5rem;
                        top: 0rem;
                        width: 4rem;
                        height: 4rem;
                        font-size: $font-form-title-tablet;
                    }

                    

                }
                &-prizes { // prize banners image
                    width: 100%;
                    margin-top: -$space-mobile-between; // -ve margin to cancel out flex container's gap as needed since img has visual spacing

                    @include tablet-query {
                        margin-top: -$space-tablet-between;
                    }

                    @include desktop-query {
                        margin-top: 0;
                    }
                }
            }
            &--last {
                text-transform: uppercase;
                @include desktop-query {
                    order: 1;
                }
            }
        }   
    }

    // Form Container
    &__form-outer-box { // added to keep legal and form together
        max-width: $space-header-content-mobile*1.05;

    }
    &__form {
        max-width: $space-header-content-mobile;
        word-wrap: normal;
        border: 1px solid $col-form-border;
        border-radius: 10px;
        
        // made upper margin larger to accomodate new preorder bar above it
        margin: $space-tablet-between 0 calc($space-mobile-between/2);
        padding: calc($space-mobile-between * 2) calc(2*$space-mobile-between) calc($space-mobile-between * 2.6);

        position: relative;

        
        @include tablet-query {
            max-width: $space-header-content-mobile + 2rem;
            // max-width: $space-header-content-tablet;
            margin-top: calc($space-header-padding-tablet/1.2); // added this for space btwn preorder & form
        }

        @include desktop-query {
            padding-top: calc($space-mobile-between*2.8);
            padding-bottom: calc($space-mobile-between*3);
        }

        &:not(.bottom-form__form) {
            @include desktop-query {
                // Shifts form contents around to account for logo at top now
                margin-top: 8rem;
                padding-top: 8rem;
            }
        }
        

        background-color: white;

        &-logo {
            display: none;

            &:not(.bottom-form__logo) {
                @include desktop-query {
                    display: block;
                    width: 8.5rem; //8rem;
                    margin: 0 auto;
    
                    position: absolute;
                    top: -5rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            
        }

        &-container {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            gap: $space-mobile-between;
        }
        &-title {
            font-size: $font-form-title-mobile;
            color: $col-brand-primary;
            text-align: center;
            @include tablet-query {
                font-size: $font-form-title-tablet;
            }
        }
        &-subtitle {
            font-size: $font-form-copy-mobile;
            color: $col-brand-primary;

            @include tablet-query {
                font-size: $font-form-copy-tablet;
            }
        }
    }
}


// Inline form (not iframed)
.form-inline {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.7rem;
    max-width: 16.2rem;
    margin: 0.2rem 1.3rem 0rem; //0.7rem;

    &--hide {
        display: none;
    }

    &__warning, &__checkbox-label, &__input::placeholder {
        font-family:Arial, Helvetica, sans-serif;
        font-size: 0.9rem;
        letter-spacing: normal;
    }
    &__input,  &__input::placeholder {
        // Form input size must be >= 16px in order to stop Safari mobile from auto-zooming into the form when click into a field
        font-size: 1rem;
    }

    &__warning {
        color: $col-error;
        font-style:italic;
        font-weight: 700;
        line-height: 1.4rem;
        padding: 0 0.1rem;

        &-hide {
            display: none;
        }
    }
    
    &__checkbox-container {
        padding: 0 0.8rem;
        display: flex;
        flex-flow: row nowrap;
    }
    &__checkbox {
        position: relative;
        top: 0.2rem;
        width: 3rem;
        // height: 32px;
        
    }
    &__checkbox-label {
        color: black;
        line-height: 1.4rem;
        vertical-align: text-top;    

        display: flex;
        align-items: flex-start;
        gap: 0.3rem;
    }
    &__input {
        // width: 100%;
        padding: 0.55rem 0.3rem;
    }
    &__button {
        position: relative;
        background-color: $col-brand-primary;
        width:12.7rem;
        padding: 0.7rem 1rem; // 0.7rem 3.3rem;
        border: none;
        border-radius: 2rem;

        font-family:"Bango Pro", "PPBango", sans-serif;
        font-size: 1.625rem;
        color: white;

        align-self: center;

        &:hover {
            cursor: pointer;
        }

        &-loading {
            width: 2rem;
            vertical-align: text-bottom;
            // position: absolute;
            // left:1.1rem;
            // bottom: 0.7rem;
        }
    }
    &__hide-misc {
        // make sure this is below other display declarations so that it has precedence
        display: none;
    }
}

// Form success message
.form__success {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-width: 18rem;
    &--hide { 
    // ENSURE THIS IS BELOW other display declarations so it has precedence
        display: none;
    }
    &-copy {
        text-align: center;
        line-height: 1.4rem;

        &-android-note {
            // used if android is not available
            font-family:Arial, Helvetica, sans-serif;
        }
    }
    &-icons-box {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.7rem;

        margin: 1rem 0;
    }
    &-icon {
        width: 9rem;
    }
}

// Form Network Error Message
.form__error {
    &-copy {
        color: $col-error;
        text-align: center;
        font-family: "Bango Pro", "PPBango",Arial, Helvetica, sans-serif;
        color: $col-error;
        line-height: 1.4rem;
        padding: 0 0.1rem;
    }
    &--hide {
        display: none;
    }
}

// Pre-Order or Download bar
.download {
    
    &__container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: $space-mobile-between - 0.2rem;

        // background: url("../assets/images/misc/bluebar.webp") bottom center / cover;
        background: url("../../../assets/images/misc/bluebar2.webp") bottom center / cover;

        padding: $space-tablet-between 0;

        border-top: 0.1rem solid #accbda;
        border-bottom: 0.1rem solid #accbda;

        @include tablet-query {
            gap: $space-tablet-between - 0.5rem;
            // padding: $space-tablet-between 0;

            border-width: 0.14rem 0;
        }
    }
    &__title {
        text-align: center;
        font-size: $font-form-title-tablet *0.7; // $font-section-title-mobile;
        
        color: white;
        text-transform: uppercase;

        text-shadow: 0px 0px 5px $col-brand-primary;

        @include medmobile-query {
            font-size: $font-form-title-tablet *0.8; // $font-section-title-mobile;
        }
        @include tablet-query {
            font-size: $font-date-tablet;
        }
        @include desktop-query {
            font-size: $font-date-desktop; 
        }

        &--top {

            text-transform: capitalize;
            font-size: $font-form-title-tablet * 0.65;

            @include medmobile-query {
                font-size: $font-form-title-tablet * 0.75;
            }

            @include tablet-query {
                font-size: $font-form-title-tablet; // $font-date-desktop*0.8;
            }
        }
    }
    &__icons-box {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 1.2rem;

        @include tablet-query {
            gap: 1.5rem;
        }
    }
    &__icon {
        width: 10rem;
        // @include verysmall-query {
        //     // This applies to screens <= 340px. Some devices were pushed out at the side with empty space due to buttons being wider than screen 
        //     width: 8rem;
        // }
        @include tablet-query {
            width: 12rem;
        }
    }
    &__upper { 
        /* must come after other "download__" CSS display directives so that display rule not overridden */
        width: 100%;
        @include desktop-query {
            display:none;
        }
    }
    &__lower {
        display: none;
        @include desktop-query {
            display: flex;
        }
    }
}