@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.news-post {
    width: 100%;
    max-width: $max-content-width;
    margin: 0 auto;

    padding: 0 $side-padding-mobile $content-gap-mobile;

    // For preheader message position adjustment
    padding-top: $preheader-height;

    @include tablet-query {
        padding: 0 $side-padding-bigdesktop $content-gap-tablet;
        
        // For preheader message position adjustment
        padding-top: $preheader-height;
    }
    @include desktop-query {
        padding: 0 calc($side-padding-bigdesktop*2) $content-gap-desktop;
    }

    &__header {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: 0.15rem;

        @include desktop-query {
            gap: 0.3rem;
        }
    }
    &__splash-image {
        width: 100%;
        max-width: $max-content-width;

        margin: $content-gap-mobile 0;
        border: none;
        border-radius: $border-radius-mobile;

        @include tablet-query {
            margin: $content-gap-tablet 0;
            border-radius: $border-radius-tablet;
        }
        @include desktop-query {
            margin: $content-gap-desktop 0;
            border-radius: $border-radius-desktop;
        }
    }
    &__category {
        text-align: center;
        color: $col-brand-primary;
        font-size: 1rem; // calc($font-body-title-mobile*0.8);
        font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
        font-weight: 300;
        

        margin-bottom: 0.1rem;        

        @include tablet-query {
            font-size: 1.2rem;
        }
        @include desktop-query {
            font-size: 1.6rem;

            margin-bottom: 0.2rem;
        }
    }
    &__title {
        // line-height: 0.8em;
        text-align: center;
        font-size: $font-section-title-mobile;

        @include tablet-query {
            font-size: $font-section-title-tablet;
        }
        @include desktop-query {
            font-size: $font-section-title-desktop;
        }
    }
    &__date {
        text-align: center;
        color: $col-text-primary;
        font-size: 0.8rem;
        font-weight: bold;
        @include tablet-query {
            font-size: 1rem;
        }
        @include desktop-query {
            font-size: 1.2rem;
        }
    }

    &__all-articles-link {
        display: block;
        text-align: right;
        color: #FA28B9;
        text-underline-offset: 0.3rem;
        // text-decoration: none;
        
        // &:hover {
        //     text-decoration: underline;
        // }

        &-icon {
            height: 1rem;
        }
    }

    &__share {
        &-box {
            padding-bottom: 1rem;
        }
        &-buttons-box {
            margin: 2rem auto 1rem;
            // padding-bottom: 1rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            max-width: 240px;
            @include desktop-query {
                max-width: 210px;
            }
            
        }
        &-button {
            // margin-right: 0.7rem;
            &:hover:not(:active) {
                opacity: 0.75;
            }
        }
    }
   
    &__body { 
        // Styles actual article body content
        // Using child selectors instead of classes as content could be done in cms with generic html elements, so we avoid specific classes
        
        margin-top: 0.3rem; // Little extra to account for space from gap above the img

        & p, & h2, & h3, & ul, & img {
            margin-bottom: 1.3rem;
        }
        & a {
            color: #FA28B9;
        }
        & img {
            display: block;
            width: 100%;
            border-radius: $border-radius-mobile;

            @include tablet-query {
                border-radius: $border-radius-tablet;
            }
            @include desktop-query {
                border-radius: $border-radius-desktop;
            }
        }
        & h2 {
            font-size: $font-body-title-mobile;

            @include tablet-query {
                font-size: $font-body-title-tablet;
            }
            @include desktop-query {
                font-size: $font-body-title-desktop;
            }
        }
        & h3 {
            color: $col-brand-primary;
            font-size: $font-body-title-mobile - 0.1rem;
            
            & a {
                color: $col-brand-primary;
                text-underline-offset: 0.3rem;
            }
            @include tablet-query {
                font-size: $font-body-title-tablet - 0.2rem;
            }
            // // Removed because site design has this header same size as h2 at mobile but much smaller at desktop
            // @include desktop-query {
            //     font-size: $font-body-title-desktop - 0.2rem;
            // }
        }

        & h5, & p strong {
            display: inline;
            font-weight: bold;
            font-size: 1rem;
            color: $col-primary;
        }

        & ul {
            list-style-type: disc;
            padding-left: 1rem;

            @include tablet-query {
                padding-left: 2rem;
            }
        }
        & li {
            margin-bottom: 1rem;
        }
    }
    &__loading {
        &-container {
            width: 100%;
            min-height: 50vh;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
        }
        &-image {
            
        }
    }
}