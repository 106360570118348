@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.news-landing {

    // width: 100%;
    // padding: $space-section-padding-mobile $side-padding-mobile;
    // background-color: $col-activities-background;
    // display: flex;
    // flex-flow: column nowrap;
    // align-items: center;
    
    // @include desktop-query {
    //     padding: $space-section-padding-tablet $side-padding-tablet;
    // }
    
    // &__title {
    //     padding-bottom: $space-section-padding-mobile; // $content-gap-mobile;
    //     font-size: $font-section-title-mobile;
        
    //     @include tablet-query {
    //         font-size: $font-section-title-tablet;
    //     }
    // }

    width: 100%;
    padding: $space-section-padding-mobile*1.2 $side-padding-mobile;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include tablet-query {
        padding: $space-section-padding-tablet*1.4 $side-padding-tablet;
    }
    @include desktop-query {
        padding-top: $space-section-padding-tablet*0.5;
    }

    &__category {
        text-align: center;
        color: $col-brand-primary;
        font-size: 1rem; // calc($font-body-title-mobile*0.8);
        font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
        font-weight: 300;
        

        margin-bottom: 0.1rem;        

        @include tablet-query {
            font-size: 1.2rem;
        }
        @include desktop-query {
            font-size: 1.6rem;

            margin-bottom: 0.2rem;
        }
    }
    &__title {
        // line-height: 0.8em;
        text-align: center;
        font-size: $font-section-title-mobile;
        margin-bottom: $content-gap-mobile; 

        @include tablet-query {
            font-size: $font-section-title-tablet;
            margin-bottom: $content-gap-tablet; 
        }
        @include desktop-query {
            font-size: $font-section-title-desktop;
            margin-bottom: $content-gap-desktop; 
        }
    }
}