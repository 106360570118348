@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.as-featured {
    
    width: 100%;
    padding: $space-section-padding-mobile $side-padding-mobile 0; // remove btm padding as next section is same color bkgd & has upper padding already
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include desktop-query {
        padding: $space-section-padding-tablet $side-padding-tablet 0;
    }

    &__title {
        padding-bottom: $space-section-padding-mobile; // $content-gap-mobile;
        font-size: $font-section-title-mobile;
        
        @include tablet-query {
            font-size: $font-section-title-tablet;
        }

        &-link {
            color: inherit;
            text-decoration: none;
        }
    }
    &__image-box {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        max-width: $max-content-width;
    }
    &__image {
        width: 40%;
        @include tablet-query {
            width: 20%;
        }
    }
}