// Import fonts
@font-face {
    font-family: 'PPBango';
    src: url('../../assets/fonts/PPBango-Regular.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bango Pro';
    src: url('../../assets/fonts/bangopro.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Metropolis Light';
    src: url('../../assets/fonts/metropolis-light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Light';
    src: url('../../assets/fonts/metropolis-bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}