@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;


.activities {
    
    width: 100%;
    padding: $space-section-padding-mobile 0;
    margin:  $space-section-padding-mobile 0 0;
    background-color: $col-activities-background;

    overflow: hidden;


    @include tablet-query {
        padding: $space-section-padding-tablet 0;
        margin:  $space-section-padding-tablet 0 0;
    }

    
    &--landing-page {
        // when component used on landing page, remove top margin as layout is different
        margin-top: 0;
    }

    &__title {
        width: 21.875rem;
        margin: 0 auto $space-section-padding-mobile;

        font-size: $font-section-title-mobile;
        text-align: center;

        line-height: 1.15;

        color: $col-primary; //$col-brand-primary;

        @include tablet-query{
            width: 36.375rem;
            font-size: $font-section-title-tablet;
            margin-bottom: $space-section-padding-mobile;
            
        }
        @include desktop-query {
            width: auto; 
            padding: 0 2rem;
        }
    }
    // &__carousel-hack {
    //     display: flex;
    //     flex-flow: row nowrap;
        
    // }
    &__item {
        
        // width: 12.5rem;
        margin: 0 $space-mobile-between;
        text-align: center;
        display: flex;
        justify-content: center;

        @include tablet-query {
            // width: 10rem; //15.625rem;
            margin: 0 $space-tablet-between; // space btwn items
        }

        &-image {
            width: 12.5rem;
            margin: 0 auto $space-mobile-between;

            @include tablet-query {
                width: 15.625rem;
                margin-bottom: 0 auto $space-tablet-between;
            }
        }
        &-desc {
            font-size: $font-activity-mobile;
            color: #0B53B4;
            font-weight: bold;

            @include tablet-query {
                font-size: $font-activity-tablet;
            }
            
        }
    }
}
