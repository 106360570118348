@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.slick {
    
    &-track {
        padding-top: 30px; // allows double quote in top left of card to display without being cropped
        padding-bottom: 10px;
        // This could also be done with overflow:visible on .slick-list, but that reveals other things inadvertantly
    }
    
}
.testimonial {
   
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    // overflow: hidden;
    padding-top: $space-section-padding-mobile*1.2;

    @include tablet-query {
        padding-top: $space-section-padding-tablet;
    }
    // @include desktop-query {
    //     padding-top: $space-section-padding-tablet;
    // }
    
    &__carousel {
        text-align: center;
        padding: 0;

        &-container { // Need this extra div as flex on parent prevents Slick carousel from working
            width: 100%;
            overflow: hidden; // without this, causes page to expand beyond window
            padding-bottom: 1.5rem; // Because overflow hidden, need this to show carousel dots
            max-width: $max-content-width - 10rem;

            // @include hugemobile-query {
            //     // padding-bottom: 0;
            // }
        }
    }

    &__title {
        // line-height: 0.8em;
        text-align: center;
        font-size: $font-section-title-mobile;
        margin-bottom: $content-gap-mobile; 

        @include tablet-query {
            font-size: $font-section-title-tablet;
            margin-bottom: $content-gap-tablet; 
        }
        @include desktop-query {
            // font-size: $font-section-title-desktop;
            margin-bottom: $content-gap-desktop; 
        }
    }

    &__box {
        position: relative;
        height: 25rem;
        min-width: 12rem;
        max-width: 14rem;
        
        @include tablet-query {
            height: 22rem;
            max-width: 18rem; //17rem;    
        }

        &-bg {
            position: absolute;
            top: 0.7rem;
            left: 0.7rem;
            height: 100%;
            width: 100%;
            background-color: $col-secondary;
        }
        &-fg {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: $col-primary;
            padding: 1rem 1.5rem 0;

            display: flex;
            flex-flow: column nowrap;
            // align-items: center;
            gap: 1rem;
        }
        
        &-corner-quote {
            position: absolute;
            top: -2.5rem;
            left: -1rem;
            font-size: 7.5rem;
            font-weight: bold;
            line-height: 7.5rem;
            // font-stretch:extra-condensed;
            color: $col-secondary;
        }
    }
    &__icon {
        width: 5rem;
        align-self: center;
    }
    &__text-body {
        font-family: "Bango Pro", "PPBango", Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.2;

        text-align: center; //left;
        color: $col-activities-bg;
    }
    &__text-name {
        font-size: 0.9rem;
        color: $col-activities-bg;
        // align-self: start;
        text-align: center; // left;
    }
}