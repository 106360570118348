
.rules {

    &__container {
        padding: 2rem 3rem;
        
        & p, & ol {
            margin: 1.3rem 0;
            line-height: 1.3rem;
            word-wrap:break-word;
        }
        & ol {
            list-style-type: decimal !important;
            
        }
    }

    
}