@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;



.preheader {
// adding this adjusted settings in header, navbar, video, and NewsArticle.  Search for 'preheader' to remove those adjustments
    position: fixed;
    top: 0;
    z-index: 99;

    width: 100%;
    height: $preheader-height;
    max-height: $preheader-height;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    padding: 0 $side-padding-mobile;

    background-color: $col-secondary; //$col-activities-background;  //white;

    @include desktop-query{
        position: relative;
    }

    &__body {
        text-align: center;
        color: $col-primary;
        font-size: 0.8rem;
        
        @include desktop-query {
            font-size: 1rem;
        }
    }
    &__link {
        color: $col-primary;
        text-decoration: none;
        font-weight: bold;
    }
}

@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}
.navbar {
    // NOTE: Also see the compact-header section below for additional styling that impacts all of these for 
    // when minimal header (no splash image) is used

    position: fixed;
    z-index: 100;
    top: $preheader-height; //0px; // was 0, but changed to 3rem to support preheader message
    left: 0;
    width: 100%;
    min-height: 4rem;
    background-color: $col-primary;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    padding: $vert-padding-mobile $side-padding-mobile 0;

    @include desktop-query {
        background: none;
        position: absolute;
        top: 0; // for preheader message, can del when not needed
        padding: $vert-padding-desktop $side-padding-desktop;
    }
    @include bigdesktop-query {
        padding: $vert-padding-desktop $side-padding-bigdesktop;
    }

    @include maxdesktop-query {
        width: $max-content-width;
        left: 50%;
        transform: translateX(-50%);
    }

    &__logo-image {
        width: 3.7rem;

        position: absolute;
        left: $side-padding-mobile;
        top: $vert-padding-mobile;
        
        @include tablet-query {
            width: 5rem;
            left: $side-padding-tablet;
        }
        
        @include desktop-query {
            width: 6rem;
            left: $side-padding-desktop; // 2rem;
            top: $vert-padding-desktop; // 1.5rem;
        }
        @include bigdesktop-query {
            left: $side-padding-bigdesktop;
        }
    }
    &__hamburger-button {
        height: 2rem;
        cursor: pointer;

        @include desktop-query {
            display: none;
        }
    }
    &__download-button-outer {
        // flex-grow: 1;
    }

    &__content-box {
        width: 100%;
        height: 100vh;

        padding-top: 5rem;
        display: flex;
        flex-flow: column wrap;
        // justify-content: center;
        align-items: center;
        
        // transitions do not work on changing the display property so use keyframe animation instead
        animation: fadeIn 0.8s;

        @include desktop-query {
            padding: 0;
            
            height: auto;
            flex-flow: row nowrap;
            justify-content: flex-end;
            gap: 1.5rem;// 2rem; // altered to 1.5 due to addition of another menu item: printables
        }
        @include bigdesktop-query { 
            gap: 2rem;
        }

        &--hide {
            display: none;

            @include desktop-query {
                // When in desktop mode, the menu should never be hidden, so make this class do nothing
                display: flex;
            }
        }
    }
    &__content-item {
        border-bottom: 2.5px solid #7ca3d7;
        width: 100%;
        padding: $vert-padding-mobile 0;
        display: flex;
        justify-content: center;

        @include desktop-query {
            // outline: 1px solid green;
            padding: 0;
            width: fit-content;
            border: none;
            display: block;
        }
    }
    &__nav-link {
        color: white;
        text-decoration: none;
        
        font-family: "Bango Pro", Arial, Helvetica, sans-serif;
        font-size: $font-page-title-mobile;

        
        @include desktop-query {
            color: $col-primary;
            font-size: $font-section-title-mobile;

            display: block; // enables scale to work
            &:hover {
                transform: scale(1.1);
                transition: transform 0.7s;
            }
        }
        &--btn {
            border: none;
            background-color: transparent;
            &:hover {
                cursor: pointer;
            }
        }
    }
    
    &__content-social-box, &__content-button-box {
        border: none;
    }
    &__content-social-box {
        padding-top: calc($vert-padding-mobile*2);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 2.3rem;

        @include desktop-query {
            padding: 0;
            gap: 1.6rem; // added this once added 'printables' to menu to get space
        }
        @include bigdesktop-query { // enough space for original spacing at this screen width
            gap: 2.3rem;
        }
    }
    &__content-social-icon {
        // NOTE: The color filters change further down below if descendant of navbar--compact-header. See further below. Separated because major
        // style changes intro'd to site after launch

        height: 1.6rem;

        @include desktop-query{
            filter: brightness(0) saturate(100%) invert(18%) sepia(95%) saturate(2768%) hue-rotate(208deg) brightness(91%) contrast(91%);
            
            &:hover {
                transform: scale(1.15);
                transition: transform 0.7s;
            }
        }

        &--yt {
            filter: brightness(0) saturate(100%) invert(94%) sepia(100%) saturate(22%) hue-rotate(20deg) brightness(105%) contrast(106%);
            
            @include desktop-query{
                height: 1.9rem;
                filter: brightness(0) saturate(100%) invert(18%) sepia(95%) saturate(2768%) hue-rotate(208deg) brightness(91%) contrast(91%);
            }
        }
    }
    &__content-button {

        @include desktop-query{
            padding: 0.6rem 1rem 0.5rem !important;
            font-size: $font-section-title-mobile !important;
            text-transform: capitalize !important; 

            &:hover {
                transform: scale(1.1);
                transition: transform 0.7s;
            }
        }
    }

    // Handles styling tweaks on navbar for the compact header (no header image). Putting it all here is not typical SCSS organization but
    // the need for this header came looong after launch, so it's a little messy
    &--compact-header { 
    
        // Make navbar fullwidth on desktop so that the color stretches across page
        @include desktop-query {
            background-color: $col-primary;
        }
        @include maxdesktop-query {
            width: 100%;
        }

        // Reposition logo to account for navbar no longer being limited in width
        & .navbar__logo-image {
            @include maxdesktop-query {
                left: 50%;
                transform: translateX(-(calc($max-content-width/2) - $side-padding-desktop));
            }
        }
        // Repositions the nav items (not incl logo) so fit in central area despite navbar now being unlimited width
        & .navbar__content-box {
            @include maxdesktop-query {
                width: $max-content-width;
                margin: 0 auto;
                padding-right: 1rem;
                
            }
            @include bigmaxdesktop-query {
                padding-right: $side-padding-desktop + 1rem; // This is padding from navbar + navbar__content-button
            }
        }
        
        // make social icons back to their original white when using the compact-header (no header image) on desktop
        & .navbar__content-social-icon {
            
            @include desktop-query {
                filter:none;

                &--yt {
                    filter: brightness(0) saturate(100%) invert(94%) sepia(100%) saturate(22%) hue-rotate(20deg) brightness(105%) contrast(106%);
                }
            }
        }
        // explore & any other text links in navbar turn white now that navbar is blue
        & .navbar__nav-link {
            @include desktop-query {
                color: white;
            }
        }
    }
}




.header {

    
    color: $col-primary;
    text-align: center;

    // next 3 adjust for the preheader message bar
    position: relative;
    top: $preheader-height;
    @include desktop-query {
        top: 0; // for preheader
    }

    // &--nopreheader {
    //     top: 0;
    //     position: static;
    // }

    &__image {
        width: 100%;
        background-image: url('../../assets/images/misc/splash-mobile.webp'), linear-gradient(to bottom,#4fa5dc 60%, white 65%);
        background-repeat: no-repeat;
        background-position: left 50% bottom 15rem;
        background-size: 100%;
    
        height: 38rem;
    
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &--non-home {
            background-position: left 50% bottom 4rem;
            height: 25rem;
        }
        
        @include medmobile-query {
            background-position: left 50% bottom 12.7rem;
            &--non-home {
                background-position: left 50% bottom 1rem;
                height: 26rem;
            }
        }
        @include commonmobile-query {
            background-position: left 50% bottom 11.5rem;
            &--non-home {
                background-position: left 50% bottom 0rem;
                height: 29rem;
            }
        }
        @include bigmobile-query {
            // all of this fiddling is to make the bg splash image & copy look good at any size really
            // background: center bottom url('../../assets/images/misc/splash-tablet.webp') no-repeat #6dcaf0;
            // background-image: url('../../assets/images/misc/splash-tablet.webp'), linear-gradient(to bottom,#6fccf1 70%, white);
            background-repeat: no-repeat;
            background-position: left 50% bottom 9rem;
            border: none;
            // background-size: 100%;
            // height: 30.5rem;
            height: 39rem; //33.5rem;

            &--non-home {
                background-position: left 50% bottom -1rem;
                height: 30rem;
            }
        }
        @include hugemobile-query {
            background-image: url('../../assets/images/misc/splash-tablet.webp'), linear-gradient(to bottom,#6fccf1 50%, white 55%);
            background-position: left 50% bottom 10rem;
            height: 33.5rem;

            &--non-home {
                background-position: left 50% bottom 1rem;
                height: 23rem;
            }
        }
        @include pretablet-query {
            height: 34.5rem;
            background-position: left 50% bottom 9rem;

            &--non-home {
                background-position: left 50% bottom 2rem;
                height: 27rem;
            }
        }
        @include tablet-query {
            height: 36rem;
            background-position: left 50% bottom 8rem;
            &--non-home {
                background-position: left 50% bottom 2rem;
                height: 30rem;
            }
        }
        @include bigtablet-query {
            height: 38.5rem;
            background-position: left 50% bottom 7rem;

            &--non-home {
                background-position: left 50% bottom 0rem;
                height: 30rem;
            }
        }
        @include desktop-query {
            background-image: url('../../assets/images/misc/splash-desktop1440b.webp'), linear-gradient(to bottom,#6fccf1 70%, white);
            background-position: left 50% bottom 8rem;
            background-size: 100%;
            height: 63vw;       
            max-height: 43rem; // 44rem;

            &--non-home {
                background-position: left 50% bottom 0rem;
                max-height: 33rem;
            }
        }
        @include bigdesktop-query {
            background-image: url('../../assets/images/misc/splash-desktop1440c.webp'), linear-gradient(to bottom,#6fccf1, #6fccf1 50%, white 60%);
            background-repeat: no-repeat;
            background-size: 105%; //108%;
            background-position: left 50% bottom 4rem; // 5rem;
            max-height: 41rem; // added to get btn above the fold on 1080

            &--non-home {
                background-position: left 50% bottom 0rem;
                height: 33rem;
            }
        }
        @include maxdesktop-query {
            // now header has fixed position & size, and more is revealed at the sides as viewport grows
            background-image: url('../../assets/images/misc/splash-wide.webp'), url('../../assets/images/misc/splash-strip.webp'), linear-gradient(to bottom,#63c6ef, #63c6ef 50%, white 60%);
            background-repeat: no-repeat, repeat-x, repeat-x;
            background-size: 2514px, 24.1px;
            
            background-position: left 50% bottom -6rem, left 0 top 2.1rem, left 0 top 0; // 5rem;
            max-height: 44rem; // added to get btn above the fold on 1080

            &--non-home {
                background-position: left 50% bottom -13rem, left 0 top 2.1rem, left 0 top 0;
                height: 37rem;
            }
        }

        // For the minimal header that doesn't include the splash image. MUST COME AFTER ALL OF BACKGROUND-IMAGE properties!
        &--compact-header { 
            background: none;
            height: 7rem;
            @include desktop-query {
                // Hides header splash image, creates a space below header so content doesn't overlap under it or the PAW logo
                height: 10.5rem;
            }
        }
    }
    
    &__content-box {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: $content-gap-mobile;
        width: 100%;
    
        // position: relative;
        // top: -3rem;
        padding: 0 $side-padding-mobile;   
    }

    &__title {
        text-align: center;
        font-size: $font-page-title-mobile;
        text-transform: uppercase;

        text-shadow: 0 -2px 1rem white;

        padding: 0 $side-padding-mobile;

        @include tablet-query {
            font-size: $font-page-title-tablet;
            text-transform:capitalize;

            padding: 0;
        }
        @include desktop-query {
            font-size: $font-page-title-desktop*0.9;
        }

        &--desktop {
            display: none;
            @include desktop-query {
                display:block;
            }
        }
        &--mobile {
            font-size: calc($font-page-title-mobile * 0.9); // reduce to 0.9 due to longer awards title
            @include med3mobile-query {
                font-size: $font-page-title-mobile;
            }
            @include tablet-query {
                font-size: $font-page-title-tablet;
            }
            @include desktop-query {
                display: none;
            }
        }
    }
    &__subtitle {
        font-size: $font-section-title-mobile;
        @include bigmobile-query {
            padding: 0 calc($side-padding-mobile*2);
        }
        
        @include desktop-query {
            font-size: $font-section-title-tablet*0.9;
        }

        &-non-mobile {
            display: none;

            @include medmobile-query {
                display: initial;
            }
        }
    }
    &__disclaimer {
        font-family: "Bango Pro", Arial, Helvetica, sans-serif;
        font-size: $font-tertiary-title-mobile;

        @include tablet-query {
            font-size: $font-tertiary-title-tablet;
        }
        @include desktop-query {
            font-size: $font-tertiary-title-desktop;
        }
    }

    
    /*
    Initially I had the rotating starburst ("spotlight") as the
    absolutely positioned element and the badge as the one in regular flow.
    However, Safari iphone & desktop would intermittently but
    reproducibly misalign the position of the abs positioned burst (it would be below the badge on top of title occasionally)
    It was definitely something to do with putting transform:translate()
    into the animation keyframes since removing animation would fix it. 
    However, I could not find that solution so I instead put starburst in flow and abs positioned the badge.
    This worked and resolved the safari bug (since not using transform:translate in the keyframes), but because the burst is in flow now, the containing element is a lot larger (to contain the burst) 
    and thus badge visually moved up as it was centered in the container.  So that required making container (.header__award) relative and manually positioning it down so that badge would 
    look like it's in the right spot. 
    */
    &__award {
        &-group {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            // Since now have a containing box for 3 items, moved some of this positioning css from header__award to header__award-group
            position: relative;
            top: 1.55rem;
            @include bigmobile-query {
                top: 2.2rem;
            }
            @include bigdesktop-query {
                top: 2.65rem; //2.6rem;
            }

            // This is the awards group that appears above splash img at certain sizes
            &-alt {
                position: absolute;
                top: 4rem; //3.8rem;
                left: 50%;
                transform: translateX(-50%);

                @include hugemobile-query {
                    top: 2.3rem;
                }
                @include hugemobile2-query {
                    display: none;
                }
            }
        }


        // position: relative;
        // top: 1.55rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        // @include bigmobile-query {
        //     top: 2.2rem;
        // }
        // @include bigdesktop-query {
        //     top: 2.6rem;
        // }

        &-image {

            &--with-spotlight { // for the google award that has animated bg starburst glow
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            // position: relative;
            // top: 50%;

            // position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            width: 15vw;
            min-width: 4rem; // 4.3rem;
            max-width: 5rem;

            @include hugemobile-query {
                width: 4.1rem;
            }
            @include hugemobile2-query {
                width: 15vw;
            }

            @include tablet-query {
                // max-width: 5rem;
            }
            @include desktop-query {
                max-width: 6.7rem;
            }

            &-secondary {
                width: 12vw;
                min-width: 3rem; // 4.3rem;
                max-width: 4rem;
    
                @include hugemobile-query {
                    width: 3.6rem;
                }
                @include hugemobile2-query {
                    width: 13vw;
                }
    
                @include tablet-query {
                    // max-width: 5rem;
                }
                @include desktop-query {
                    max-width: 5.5rem; //5.7rem;
                }
                @include maxdesktop-query {
                    max-width: 5.7rem;
                }
            }

        }
        &-spotlight {
            
            animation: 20s linear 0s infinite spotlightAnim2;
            width: 30vw;
            min-width: 6.5rem;
            max-width: 9rem;

            @include bigmobile-query {
                max-width: 9.5rem;
            }

            @include desktop-query {
                max-width: 11.7rem;
            }
        }

        // This is the alternate award badge that appears near top of screen when it's too crowded above the text.
        &-alt {
            // // Don't need these positioning anymore as moved into containing header__award-group-alt
            // position: absolute;
            // top: 3.8rem;
            // left: 50%;
            // transform: translateX(-50%);

            @include med3mobile-query {
                display: none;
            }
            @include hugemobile-query {
                display: block;
                // top: 2.3rem;
            }
            @include hugemobile2-query {
                display: none;
            }
        }
        // hides/shows the primary award badge that sits near the title
        &-primary{
            display: none;
            
            @include med3mobile-query {
                display: block;
            }
            @include hugemobile-query {
                display: none
            }
            @include hugemobile2-query {
                display: block;
            }
        }
    }

}
// For the starburst animation behind the Google Awards badge
@keyframes spotlightAnim2 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
