@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;

.printables-landing {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    padding: $space-section-padding-mobile*1.2 $side-padding-mobile;

    @include tablet-query {
        padding: $space-section-padding-tablet*1.4 $side-padding-tablet;
    }
    @include desktop-query {
        padding-top: $space-section-padding-tablet*0.5;
    }

    &__header-box {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        height: 17rem;
        border-radius: $border-radius-mobile;
        width: 100%;
        max-width: $max-content-width;
        
        padding: $vert-padding-mobile;
        margin-bottom: calc($content-gap-mobile*1.3);

        background: url('../../assets/images/printables/printables_header.webp');
        background-position: center 95%; //bottom;
        background-size:cover;
        
        @include bigmobile-query {
            background-size: 100rem;

        }

        @include tablet-query {
            height: 20rem;
            background-size: 120rem;
            padding: $vert-padding-tablet;
            margin-bottom: calc($content-gap-tablet *1.5);
            
        }
        @include desktop-query {
            height: 25rem;
            background-size: 150rem;
            padding: $vert-padding-desktop;
            margin-bottom: $content-gap-tablet;
        }
        @include maxdesktop-query {
            background-size: 170rem;
            background-position: center 90%;
        }
    }
    &__title, &__subtitle {
        text-transform: uppercase;
        text-align: center;
    }
    &__subtitle {
        font-size: 1rem;
        color: $col-brand-primary;
        @include bigmobile-query {
            font-size: 110%;
        }

        @include tablet-query {
            font-size: $font-body-title-tablet;
            // width: 65%;
        }
        @include desktop-query {
            font-size: $font-body-title-desktop;
        }
    }
}

.printables-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    max-width: $max-content-width;

    @include desktop-query {
        flex-flow: row wrap;
        // align-items: flex-start;
        align-items: stretch;
        justify-content: center;

        gap: $side-padding-mobile;
    }
    @include bigdesktop-query {
        gap: $content-gap-tablet;
    }


    // Related to printable item box within a printables list
    &__item {
        
        display: flex;
        flex-flow: column nowrap;
        flex: 1 70%; // 70% was added to try to address iPhone issue where items not filling up width on initial load, but unnecessary.
        margin-bottom: calc($content-gap-mobile*1.2); // use margin not padding since whole item is linked, so reduce accidental clicks
        width: 100%;
        max-width: 430px; // adjust this to same var used to set width on content in offers and other sections
        // transition: 1s ease;

        background-color: $col-primary;
        border-radius: $border-radius-mobile;
        overflow:hidden;

        @include tablet-query {
            margin-bottom: $content-gap-desktop;
        }

        @include desktop-query {
            flex: 1 26%; // 26% ensures 3 per row 
            margin-bottom: 0;
            max-width: 411px; // Ensures that items on rows that are not full will not be wider than items on full rows (411 is width of items on full rows on desktop)
        }

        &-unstyled-link {
            color: inherit;
            text-decoration: none;
        }
        &-link {
            color: inherit;
            text-decoration: none;
            aspect-ratio: 16 / 9;
            width: 100%;
            // min-width: 411px;
            overflow: hidden;
        }
        &-thumb {
            width: 100%;
            object-fit: cover;
            // object-position: 0 0;
        }
        &-text-box {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            // justify-content: space-evenly;
            gap: 0.3rem;
            padding: 0.8rem;
        }
        &-category {
            color: $col-secondary;
            font-size: 1rem; // calc($font-body-title-mobile*0.8);
            line-height: 0.8rem;
            font-family: "PPBango", "Bango Pro", Arial, Helvetica, sans-serif;

            @include tablet-query {
                font-size: 1.2rem;
                line-height: 1.1rem;
            }
        }
        &-title {
            text-align: left;
            color: white;
            font-size: $font-body-title-mobile;
            // letter-spacing: 0.03rem;
            

            // @include hugemobile-query {
            //     font-size: $font-form-title-mobile;
            // }
            @include tablet-query {
                font-size: $font-form-title-tablet;
            }
        }
        &-print-share-box {
            margin-top: 0.1rem; // Addresses visually anomaly where the gap above is smaller
            width:100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }
        &-print-btn {
            color: $col-brand-primary;
            background-color: white;
            font-family: "PPBango", "Bango Pro", Arial, Helvetica, sans-serif;
            font-size: 1rem;
            // line-height: 1rem;
            font-weight: 300;
            text-transform: uppercase;

            padding: 0.4rem 0.8rem;
            border: none;
            border-radius: 0.6rem;
            cursor: pointer;    
        
            &:hover {
                transform: scale(1.1);
                transition: transform 0.8s ease-in-out;
            }

            @include tablet-query {
                padding: 0.5rem 1rem;
                font-size: 1.1rem;
                // line-height: 1.1rem;
            }
        }
        &-share-container {
            position: relative;
        }

        &-share-icons-box {
            position: absolute;
            top: -5.5rem;
            right: 0;
            display: flex;
            flex-flow: row nowrap;
            gap: 0.6rem;
            padding: 1rem;
            background-color: $col-primary; // white;
            // background-color: white;
            border: 2px solid $col-secondary;
            border-radius: $border-radius-mobile;

            box-shadow: 0 0.3rem 0.3rem grey;

            display: none;

            &--active {
                display:flex;
            }
        }
        // Close X button on share icons
        &-share-close {
            position: absolute;
            right: 0;
            top: 0;
        }
        &-share-landing-icon {
            height: 0.8rem;   
            margin-right: 0.3rem;

            @include tablet-query {
                height: 1rem;
            }

        }
        &-share-link {
            border: none;
            color: $col-secondary;
            background-color: transparent;
            font-family: "PPBango", "Bango Pro", Arial, Helvetica, sans-serif;
            font-size: 1rem;
            line-height: 1rem;
            cursor:pointer;
            // color: none;

            display: flex;
            flex-flow: row nowrap;
            align-items:baseline;

            &--active {
                text-decoration: underline;
            }
            @include tablet-query {
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
        }
        &-placeholder {
            color: $col-text-primary;

        }
        &--unavailable {
            opacity: 0.6;

            &:hover {
                transform: none;
            }
        }

    }
}